import React from "react"
import Layout from "../components/layout"
import quoteStyle from "../components/quote.module.css"
export default () => {
  return (
    <Layout>
      <div className={quoteStyle.container}>
        <blockquote>
          Każdy człowiek powinien rozwijać w sobie szczególnie to, co inni mu
          wytykają. To jest prawdopodobnie jego najmocniejszą stroną.
        </blockquote>
        <cite>Jean Cocteau</cite>
      </div>
    </Layout>
  )
}
